<template>
  <div class="mt-4">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Proyek</label>
        <vs-input class="w-full" :value="initData.cashAdvance.nama_proyek" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">No. Cash Advance</label>
        <vs-input class="w-full" :value="initData.cashAdvance.no_cash_advance" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Tanggal</label>
        <vs-input class="w-full" :value="initData.cashAdvance.tgl_pengajuan" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Diajukan Oleh</label>
        <vs-input class="w-full" :value="initData.cashAdvance.nama_staf" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Created By</label>
        <vs-input class="w-full" :value="initData.cashAdvance.username" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Kategori</label>
        <vs-input class="w-full" :value="initData.cashAdvance.kategori" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Total Nominal</label>
        <v-money class="w-full" :value="initData.cashAdvance.total_nominal" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <label class="ml-1 text-xs">Judul</label>
        <vs-input class="w-full" :value="initData.cashAdvance.judul" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <label class="ml-1 text-xs">Keterangan</label>
        <vs-input class="w-full" :value="initData.cashAdvance.keterangan" readonly/>
      </div>
    </div>
    <div v-if="initData.cashAdvance.kategori === 'BUSINESS TRAVEL'">
      <vs-divider class="mt-base">Detail DLK</vs-divider>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Nomor</label>
          <vs-input class="w-full" v-model="initData.cashAdvance.no_dlk" readonly/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Tgl. Mulai *</label>
          <vs-input class="w-full" v-model="initData.cashAdvance.tgl_mulai" readonly></vs-input>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Tgl. Selesai *</label>
          <vs-input class="w-full" v-model="initData.cashAdvance.tgl_selesai" readonly></vs-input>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Tempat Tujuan *</label>
          <vs-input class="w-full" v-model="initData.cashAdvance.tempat_tujuan" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-12/12 w-full">
          <label class="ml-1 text-xs">Tujuan Dinas *</label>
          <vs-input class="w-full" v-model="initData.cashAdvance.tujuan_dinas" readonly/>
        </div>
      </div>
    </div>

    <vs-divider class="mt-base">Estimasi Pengeluaran</vs-divider>

    <div class="vx-row mb-3 mt-base">
      <div class="vx-col sm:w-12/12 w-full">
        <vs-table :data="initData.cashAdvanceD" class="fix-table-input-background">
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">Nomor</vs-th>
            <vs-th class="whitespace-no-wrap">Nama Item</vs-th>
            <vs-th class="whitespace-no-wrap">Nominal</vs-th>
            <vs-th class="whitespace-no-wrap">Files</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td class="whitespace-no-wrap">{{ item.no_cash_advance_d }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.nama_item }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.nominal | idr }}</vs-td>
              <vs-td>
                <div class="whitespace-no-wrap">
                  <span v-if="item.files_url.length > 0" class="text-xs underline cursor-pointer" @click="showFiles(item)">Lihat Files</span>
                  <span v-else class="text-sm">-</span>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <!--modals-->
    <FilesViewer :isActive.sync="modalFiles.active" :filesUrl="modalFiles.filesUrl"/>
  </div>
</template>

<script>
import FilesViewer from '@/views/components/files-viewer/FilesViewer'
import VMoney from '@/views/components/v-money/VMoney'

export default {
  name: 'TabDetail',
  props: ['isActive', 'initData'],
  components: {
    FilesViewer,
    VMoney
  },
  data () {
    return {
      modalFiles: {
        filesUrl: [],
        active: false
      }
    }
  },
  methods: {
    showFiles (item) {
      this.modalFiles.filesUrl = item.files_url
      this.modalFiles.active = true
    }
  }
}
</script>
